<template>
  <div class="event md-layout">
    <div class="event-content md-layout-item md-layout md-size-100 nopadding">
      <div class="event-text md-layout-item">
        {{ event.text }}
      </div>
<!--
      <div class="icons-container md-layout-item md-layout md-size-20 md-alignment-top-right">
        <div class="icon-container md-layout-item md-size-100 nopadding">
          {{ event.importance }} <md-icon>star</md-icon>
        </div>
        <div class="icon-container md-layout-item md-size-100 nopadding">
          {{ event.urgency }} <md-icon>timer</md-icon>
        </div>
      </div>
-->
    </div>
    <div class="event-footer md-layout-item">
      <div class="event-tags">
        <md-chip
          class="md-info"
          v-for="tag in event.tags"
          :key="tag.id"
          md-disabled
          >{{ tag.name }}</md-chip
        >
      </div>
      <div class="timestamp">
        {{ event.createdDate | moment("from") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'event',
  components: {},
  props: {
    event: Object,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.event {
  // background-color: rgba(188, 198, 204, 0.2);
  padding: 0.2em 0em 0.2em;
  margin: 0em 0.4em 0.4em 0em;
  border-radius: 0.6em;
  cursor: default;
  //max-height: 14vh;
}

.icon-container {
  text-align: right;
  font-size: 0.8em;
  line-height: 1em;
}
.event-footer {
  display: flex;
  padding-bottom: 0.3rem;
}
.timestamp {
  margin-left: auto;
  color: $avalia-cyan;
  text-align: right;
}
</style>
