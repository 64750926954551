<template>
  <div>
    <span class="task-type-header">
      <h6 class="task-type-label">{{ title }}</h6>
      <div v-if="buttonDisplayed">
        <span @click="createTask" class= "create-task-button-container">
          <md-icon class="icon-action icon-add">add</md-icon>
          <md-tooltip md-delay="300">Create Task</md-tooltip>
        </span>
      </div>
    </span>
    <tasks-list
      :tasks="tasks"
      :isReadOnly="isReadOnly"
      @workOnTask="onWorkOnTask"
      @startTask="onStartTask"
      @viewTask="onViewTask"
    ></tasks-list>
  </div>
</template>

<script>
import TasksList from '@/components/Avalia/ProjectDetails/Tasks/TasksList.vue';

export default {
  name: 'tasks-tab',
  components: { TasksList },
  props: {
    tasks: Array,
    title: String,
    displayButton: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    buttonDisplayed() {
      return this.displayButton && !this.isReadOnly;
    },
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
    onStartTask(task) {
      this.$emit('startTask', task);
    },
    onWorkOnTask(task) {
      this.$emit('workOnTask', task);
    },
    onViewTask(task) {
      this.$emit('viewTask', task);
    },
    createTask() {
      this.$emit('createTask');
    },
  },
};
</script>

<style lang="scss" scoped>
.task-type-label {
  margin: 0.5rem 0rem 0.5rem 0.5rem;
}
.task-type-header {
  display: flex;
  justify-content: space-between;
}
.create-task-button-container {
  padding-right: 1rem;
}
</style>
