<template>
  <div>
    <nav-tabs-card class="noshadow">
      <template slot="content">
        <span class="md-nav-tabs-title custom-nav-tabs-title">{{ title }}</span>
        <md-tabs class="md-custom-color" md-alignment="centered" :md-swipeable="true" @md-changed="onTabChanged">
          <template slot="md-tab" slot-scope="{ tab }">
            <md-icon class="md-size-2x"> {{ tab.icon }}</md-icon>
            <md-tooltip md-direction="top" md-delay="100"> {{ tab.data.tooltip }} </md-tooltip>
          </template>
          <md-tab id="tab-tasks-available" class="tab-tasks" md-icon="assignment" :md-template-data="{ tooltip: 'Available Tasks'}">
            <tasks-tab
              id="tasksAvailable"
              :tasks="tasksAvailable"
              :title="tasksAvailableTitle"
              :displayButtonText="createTaskButtonText"
              :displayButton="true"
              :isReadOnly="isReadOnly"
              @workOnTask="onWorkOnTask"
              @startTask="onStartTask"
              @createTask="onCreateTask"
              @viewTask="onViewTask"
            ></tasks-tab>
          </md-tab>
          <md-tab id="tab-tasks-completed" class="tab-tasks" md-icon="assignment_turned_in" :md-template-data="{ tooltip: 'Completed Tasks'}">
            <tasks-tab
              id="tasksCompleted"
              :tasks="tasksCompleted"
              :title="tasksCompletedTitle"
              :isReadOnly="isReadOnly"
              @viewTask="onViewTask"
            ></tasks-tab>
          </md-tab>
          <md-tab id="tab-tasks-locked" md-icon="lock" :md-template-data="{ tooltip: 'Locked Tasks'}">
            <tasks-tab
              id="tasksLocked"
              :tasks="tasksLocked"
              :title="tasksLockedTitle"
              :isReadOnly="isReadOnly"
              @viewTask="onViewTask"
            ></tasks-tab>
          </md-tab>

        </md-tabs>

      </template>
    </nav-tabs-card>
  </div>
</template>

<script>
import { NavTabsCard } from '@/components/Template';
import TasksTab from '@/components/Avalia/ProjectDetails/Tasks/TasksTab.vue';

export default {
  name: 'tasks-card',
  components: {
    NavTabsCard,
    TasksTab,
  },
  props: {
    tasksAvailable: Array,
    tasksCompleted: Array,
    tasksLocked: Array,
    title: String,
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tasksAvailableTitle: 'Available',
      tasksCompletedTitle: 'Completed',
      tasksLockedTitle: 'Locked',
      createTaskButtonText: 'Create Task',
    };
  },
  computed: {},
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
    onTabChanged(event) {
      // Scroll tab back up on tab change
      const elements = document.getElementsByClassName('md-tabs-content');
      const element = elements[0];
      if (element) {
        element.scrollTop = 0;
      }
    },
    onStartTask(task) {
      this.$emit('startTask', task);
    },
    onWorkOnTask(task) {
      this.$emit('workOnTask', task);
    },
    onViewTask(task) {
      this.$emit('viewTask', task);
    },
    onCreateTask() {
      this.$emit('createTask');
    },
  },
};
</script>

<style lang="scss" scoped>
.md-tabs.md-custom-color {
  &::v-deep .md-tabs-navigation {
    background-color: $avalia-gray;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
    padding-left: 75px;
  }
}
.tab-tasks {
    padding-top: 0.6rem;
}
.custom-nav-tabs-title{
  font-size:1.0625rem;
}

</style>
