<template>
  <div class="tasks-list">
    <single-task-view
      v-for="task in tasks"
      :key="task.id"
      :task="task"
      :isReadOnly="isReadOnly"
      @startTask="onStartTask"
      @workOnTask="onWorkOnTask"
      @viewTask="onViewTask"
    ></single-task-view>
  </div>
</template>

<script>
import SingleTaskView from '@/components/Avalia/ProjectDetails/Tasks/SingleTaskView.vue';

export default {
  name: 'tasks-list',
  components: { SingleTaskView },
  props: {
    tasks: Array,
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onStartTask(task) {
      this.$emit('startTask', task);
    },
    onWorkOnTask(task) {
      this.$emit('workOnTask', task);
    },
    onViewTask(task) {
      this.$emit('viewTask', task);
    },
  },
};
</script>

<style lang="scss" scoped></style>
