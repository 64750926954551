<template>
  <modal @close="hide">
    <template slot="header">
      <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hide">
        <md-icon>clear</md-icon>
      </md-button>
      <h4 class="modal-title">{{ title }}</h4>
    </template>

    <template slot="body">
      <team-manager
        :usersOptions="usersOptions"
        :currentUser="currentUser"
        @setTeam="setParticipants"
        @addToUsersList="addToUsersOptions"
        @removeFromUsersList="removeFromUsersOptions"
        :rolesOptions="existingProjectRoles"
      />
      <div class="buttons-container">
      <md-button class="md-danger modal-button md-sm cancel-button" @click="hide">Cancel</md-button>
      <md-button
        class="md-success md-sm confirm-button"
        :disabled="hasNoNewParticipants"
        @click="addParticipants"
      >
        Confirm
      </md-button>
      </div>
    </template>
  </modal>
</template>

<script>
import api from '@/api/api';
import { Modal } from '@/components/Template';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import notify from '@/utils/notificationsUtils';
import TeamManager from '../TeamManager/TeamManager.vue';

extend('required', required);

export default {
  name: 'add-participants-modal',
  components: { Modal, TeamManager },
  props: {
    existingUsers: {
      type: Array,
      default: () => [],
    },
    existingProjectRoles: {
      type: Array,
      default: () => [],
    },
    projectId: Number,
    team: Array,
  },
  data() {
    return {
      title: 'Add Participants',
      usersOptions: this.existingUsers,
      currentUser: {},
      newMembers: [],
    };
  },
  computed: {
    getCurrentUserEmail() {
      return this.$store.getters['users/GET_CURRENT_USER_EMAIL'];
    },
    hasNoNewParticipants() {
      return this.newMembers.length < 1;
    },
  },
  watch: {},
  created() {
    // On created
    // this.usersOptions = this.existingUsers;
    // this.rolesOptions = this.existingProjectRoles;
    // this.fetchProjectDetails();
  },
  mounted() {
    // Filter for having only members not yet in project
    this.team.forEach((member) => this.removeFromUsersOptions(member));
  },
  methods: {
    resetEvents() {
      this.$store.dispatch('events/resetEvents');
    },
    fetchProjectDetails() {
      return this.$store.dispatch('project/fetchProjectDetails', {
        projectId: this.projectId,
        vm: this,
      });
    },
    addParticipants() {
      // TO DO CHANGE THIS
      // console.log('this.newMembers', this.newMembers);
      const participantsPayload = {
        projectId: this.projectId,
        participations: this.newMembers.map((p) => ({
          userEmail: p.email,
          roles: p.roles,
        })),
      };
      api.assess
        .put('/participations', participantsPayload)
        .then((response) => {
          this.hide();
          this.fetchProjectDetails().then(() => {
            this.resetEvents();
            const successMessage = 'Participant successfully Added';
            notify.notifySuccess(successMessage, this);
          });
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Add Participant: ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    hide() {
      this.$emit('close');
    },
    setParticipants(members) {
      this.newMembers = members;
    },
    addToUsersOptions(value) {
      this.usersOptions.push(value);
    },
    removeFromUsersOptions(value) {
      this.usersOptions = this.usersOptions.filter((user) => user.email !== value.email);
    },
  },
};
</script>

<style lang="scss" scoped>
.participants-autocomplete {
  padding-top: 1.5rem;
}
.participants-autocomplete-item {
  padding-left: 0rem;
}
.buttons-container {
  padding-top: 0.4rem;
}
.modal-button {
  margin-right: 0.4rem;
}
// CUSTOM BUTTONS TO MOVE TO avalia.scss
.confirm-button {
  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }
}
.cancel-button {
  color: #fe0600 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #fe0600 !important;
    opacity: 0.8 !important;
    border-color: #fe0600 !important;
  }
}
</style>
