<template>
  <div>
    <roles-selector-modal
      v-if="open"
      :rolesProps="userSelected.roles"
      :rolesOptions="rolesOptions"
      @close="handleCloseRolesModal"
      @rolesChange="handleRolesChange"
      :userFirstName="userSelected.displayName"
    />
  </div>
</template>

<script>
import RolesSelectorModal from '../Modals/RolesSelectorModal.vue';

export default {
  components: {
    RolesSelectorModal,
  },
  props: {
    projectId: Number,
    rolesOptions: Array,
    open: Boolean,
    user: Object,
  },
  data() {
    return {
      userSelected: this.user,
    };
  },
  mounted() {
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.userSelected = newVal;
        if (newVal.displayName && newVal.displayName !== '') {
          this.isUserSelected = true;
        } else {
          this.isUserSelected = false;
        }
      }
    },
  },
  computed: {
  },
  methods: {
    handleRolesChange(roles) {
      const participants = {
        projectId: this.projectId,
        participations: [
          {
            userEmail: this.userSelected.email,
            roles,
          },
        ],
      };
      this.$emit('editRoles', participants);
    },
    handleCloseRolesModal() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.tooltip-modal {
  z-index: 10000;
}
.user {
  text-align: left;
}
</style>
