<template>
  <div class="feedback md-layout">
    <div
      class="feedback-content md-layout-item md-layout md-size-100 nopadding"
    >
      <div class="feedback-icon md-layout-item md-size-10">
        <md-icon :class="getHealthClassFromFeedbackValue">
          <i class="fa fa-heartbeat"></i>
        </md-icon>
      </div>
      <div class="feedback-text md-layout-item md-size-30">
        {{ feedback.user.displayName }}
      </div>
      <div class="feedback-details md-layout-item md-size-60">
        {{ feedback.details }}
      </div>
    </div>
    <div class="feedback-footer md-layout-item md-layout">
      <div class="timestamp md-layout-item md-size-100 nopadding">
        {{ feedback.createdDate | moment("from") }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  utilsGetHealthClassFromFeedbackValue,
} from '@/utils/utils';

export default {
  name: 'feedback',
  components: {},
  props: {
    feedback: Object,
  },
  data() {
    return {};
  },
  computed: {
    getHealthClassFromFeedbackValue() {
      return utilsGetHealthClassFromFeedbackValue(this.feedback.projectHealth);
    },
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.feedback {
  // background-color: rgba(188, 198, 204, 0.2);
  padding: 0.2em 0em 0.2em;
  margin: 0em 0.4em 0.4em 0em;
  border-radius: 0.6em;
  cursor: default;
}
.icon-container {
  text-align: right;
  font-size: 0.8em;
  line-height: 1em;
}
.feedback-footer {
  padding-bottom: 0.3rem;
}
.timestamp {
  color: $avalia-cyan;
  text-align: right;
}
.feedback-icon {
}

.feedback-element{
  padding-left: 0.6rem;
}
</style>
