<template>
  <div>
    <notes-project-container></notes-project-container>
  </div>
</template>

<script>

import NotesProjectContainer from '@/components/Avalia/Note/NotesProjectContainer.vue';

export default {
  name: 'note-tab',
  components: {
    NotesProjectContainer,
  },
  props: {
    projectId: null,
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    notes() {
      return this.$store.getters['notes/GET_PROJECT_NOTES'];
    },
  },
  watch: {
  },
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
    onSaveNote(notePayload) {
      if (notePayload.id) {
        this.$emit('updateNote', notePayload);
      } else {
        this.$emit('createNote', notePayload);
      }
    },
    onDeleteNote(notePayload) {
      this.$emit('deleteNote', notePayload);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
