<template>
  <div class="md-layout event-feed-layout">
    <!--
    <div class="event-feed-header md-layout-item md-size-100">
      <span>
        <h6>Event Feed</h6>
      </span>
    </div> -->
    <div class="event-feed-content md-layout-item md-size-100">
      <event
        v-for="(event, index) in events"
        :key="index"
        :event="event"
        class=""
      ></event>
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
        <div slot="no-results">No event</div>
        <div slot="no-more">No more event</div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

import Event from '@/components/Avalia/ProjectDetails/Interactions/Event.vue';

export default {
  name: 'events-feed-tab',
  components: {
    InfiniteLoading,
    Event,
  },
  props: {
    projectId: null,
  },
  data() {
    return {};
  },
  computed: {
    events() {
      return this.$store.getters['events/GET_EVENTS'];
    },
    infiniteId() {
      return this.$store.getters['events/GET_INFINITE_ID'];
    },
  },
  watch: {
    projectId(newValue, oldValue) {
      this.$store.dispatch('events/resetEvents');
    },
  },
  created() {
  },
  mounted() {
    // On mounted
    this.$store.dispatch('events/resetEvents');
  },
  methods: {
    infiniteHandler(infiniteState) {
      const payload = {
        projectId: this.projectId,
        infiniteState,
        vm: this,
      };
      this.$store.dispatch('events/fetchProjectEvents', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.event-feed-layout {
  //overflow-y: scroll;
  //min-height: 65vh;
  //max-height: 65vh;
  align-content: flex-start;
}
.event-feed-content {
  padding-bottom: 0.5rem;
  //overflow-y: scroll;
}
</style>
