<template>
  <div>
    <!-- <nav-tabs-card> -->
    <nav-tabs-card class="noshadow">
      <template slot="content">
        <md-tabs
          class="md-custom-color"
          md-alignment="centered"
          @md-changed="onTabChanged"
          md-dynamic-height
          ref="tabs"
        >
          <!-- tabs -->
          <md-tab id="tab-events" md-label="Events" md-icon="dynamic_feed">
            <events-feed-tab :projectId="projectId" v-if="isActiveTab('tab-events')"></events-feed-tab>
          </md-tab>
          <!-- To add badges of unread comments https://vuematerial.io/components/tabs#tab-custom-template -->
          <md-tab id="tab-feedback" md-label="Feedback" md-icon="feedback">
            <feedback-tab
              class="tab-feedback"
              :projectId="projectId"
              :isReadOnly="isReadOnly"
              @sendFeedback="onSendFeedback"
              v-if="isActiveTab('tab-feedback')"
            ></feedback-tab>
          </md-tab>

          <md-tab id="tab-note" md-label="Notes" md-icon="sticky_note_2" >
            <note-tab :projectId="projectId" v-if="isActiveTab('tab-note')"></note-tab>
          </md-tab>
          <!--
          <md-tab id="tab-comment" md-label="Comments" md-icon="comment" v-if="isActiveTab('tab-comment')">
            <comment-tab v-if="anchor" :anchor="anchor" :isSelected="isCommentsTabSelected" ></comment-tab>
          </md-tab>
          -->
        </md-tabs>
      </template>
    </nav-tabs-card>
  </div>
</template>

<script>
import { NavTabsCard } from '@/components/Template';
// import CommentTab from '@/components/Avalia/ProjectDetails/Interactions/CommentTab.vue';
import FeedbackTab from '@/components/Avalia/ProjectDetails/Interactions/FeedbackTab.vue';
import EventsFeedTab from '@/components/Avalia/ProjectDetails/Interactions/EventsFeedTab.vue';
import NoteTab from '@/components/Avalia/ProjectDetails/Interactions/NoteTab.vue';
// import EventBus from '@/event-bus.js';

export default {
  name: 'interactions-view',
  components: {
    NavTabsCard,
    // CommentTab,
    FeedbackTab,
    EventsFeedTab,
    NoteTab,
  },
  props: {
    projectId: null,
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tabChoose: 'tab-event',
    };
  },
  computed: {
    anchor() {
      return this.project.anchor;
    },
    project() {
      return this.$store.getters['project/GET_PROJECT'];
    },
    isCommentsTabSelected() {
      return this.tabChoose === 'tab-comment';
    },
  },
  watch: {},
  created() {},
  mounted() {
    // On mounted
  },
  methods: {
    isActiveTab(tab) {
      return this.tabChoose === tab;
    },
    onSendFeedback(feedback) {
      this.$emit('sendFeedback', feedback);
    },
    onTabChanged(event) {
      // Save potentially unsaved notes
      // EventBus.$emit('saveNotes');
      // Scroll tab back up on tab change
      const elements = document.getElementsByClassName('md-tabs-content');
      const element = elements[0];
      if (element) {
        element.scrollTop = 0;
      }
      this.tabChoose = event;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-tabs.md-custom-color {
  &::v-deep .md-tabs-navigation {
    background-color: $avalia-gray;

    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 1rem;
  }
  &::v-deep .md-tab-label {
    font-size: 14px;
  }
}
#tab-feedback {
  padding: 0 0 0 0 !important;
  min-height: 175px
}
#tab-note {
  overflow: auto;
}
</style>
