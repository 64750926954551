<template>
  <div>
    <md-dialog :md-active.sync="showDialogShortcut" >
      <md-dialog-title>Shortcuts</md-dialog-title>
      <div class="shortcut-container">
        <span class="shortcut shortcut-text">CTRL/CMD + S:</span><span class="shortcut-text"> saved note</span>
        <span class="shortcut shortcut-text">CTRL/CMD + D:</span><span class="shortcut-text"> delete note</span>
        <span class="shortcut shortcut-text">CTRL/CMD + ENTER:</span><span class="shortcut-text"> new note</span>
        <span class="shortcut shortcut-text">CTRL/CMD + UP:</span><span class="shortcut-text"> focus previous note</span>
        <span class="shortcut shortcut-text">CTRL/CMD + DOWN:</span><span class="shortcut-text"> focus next note</span>
        <span class="shortcut shortcut-text">CTRL/CMD + Z:</span><span class="shortcut-text"> undo</span>
        <span class="shortcut shortcut-text">CTRL/CMD + SHIFT + Z:</span><span class="shortcut-text"> redo</span>
        <span class="shortcut shortcut-text">CTRL/CMD + B:</span><span class="shortcut-text"> bold</span>
        <span class="shortcut shortcut-text">CTRL/CMD + I:</span><span class="shortcut-text"> italic</span>
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="hideInfoShortcutDialog">Close</md-button>
      </md-dialog-actions>
    </md-dialog>
    <span @click="showInfoShortcutDialog">
      <md-icon class="icon-action icon-info">info</md-icon>
      <md-tooltip md-delay="300">Display shortcuts</md-tooltip>
    </span>
  </div>
</template>
<script>
export default {
  name: 'shortcuts-info',
  data() {
    return {
      showDialogShortcut: false,
    };
  },
  methods: {
    showInfoShortcutDialog() {
      this.showDialogShortcut = true;
    },
    hideInfoShortcutDialog() {
      this.showDialogShortcut = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.info-container {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}
.shortcut-container {
  padding: 0 24px 15px 24px;
  width: 425px;
  display: flex;
  flex-wrap: wrap;
}
.shortcut {
  font-weight: bold;
}
.shortcut-text {
  width: 50%;
}
</style>
