<template>
  <div>
    <div v-if="showFeedbackForm">
      <div class="feedback-action-container">
        <div class="feedback-form-container md-layout md-alignment-center-right">
          <div class="feedback-form md-layout-item md-size-90">
            <div class="icon-status-container">
              <md-icon :class="getHealthClass">
                <i class="fa fa-heartbeat"></i
              ></md-icon>
            </div>
            <div>
              <md-field class="feedback-status-select">
                <label>Feedback</label>
                <md-select
                  id="feedback"
                  name="feedback"
                  v-model="feedback.projectHealth"
                >
                  <md-option
                    v-for="(option, index) in feedbackOptions"
                    :key="index"
                    :value="option.projectHealth"
                    :label="option.name"
                    :class="`bg-${option.color}`"
                    >{{ option.name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="detail-field-container">
              <md-field class="detail-field">
                <label>Detail</label>
                <md-input v-model="feedback.details"
                  type="text"
                  @keydown.ctrl.s.prevent.stop="() => onSave()"/>
              </md-field>
            </div>
          </div>
          <div v-if="feedbackIsSelected" class="actions-container md-layout-item md-size-10">
            <div >
              <span @click="sendFeedback">
                <md-icon class="icon-action icon-save">save</md-icon>
                <md-tooltip md-delay="300">Save [ctrl/cmd + s]</md-tooltip>
              </span>
            </div>
            <div>
              <span @click="resetFeedback">
                <md-icon class="icon-action icon-delete">clear</md-icon>
                <md-tooltip md-delay="300">Cancel</md-tooltip>
              </span>
            </div>
          </div>
        </div>
        <div class="feedback-display-options">
          <md-radio v-model="displayAllFeedbacks" :value="true"
            >Display All Feedbacks</md-radio
          >
          <md-radio v-model="displayAllFeedbacks" :value="false"
            >Display My Feedbacks</md-radio
          >
        </div>
      </div>
    </div>
    <!-- Feedbacks Stream -->
    <div class="md-layout">
      <div
        class="md-layout md-layout-item md-size-100 feedbacks-stream-container nopadding"
      >
        <div class="md-layout-item md-size-100 feedbacks-stream">
          <feedback
            v-for="(feedback, index) in feedbacks"
            :key="index"
            :feedback="feedback"
          >
          </feedback>
          <infinite-loading
            :identifier="infiniteId"
            @infinite="infiniteHandler"
          >
            <div slot="no-results">No feedback</div>
            <div slot="no-more">No more feedback</div>
          </infinite-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import { utilsGetHealthClassFromFeedbackValue } from '@/utils/utils';
import Feedback from '@/components/Avalia/ProjectDetails/Interactions/Feedback.vue';

export default {
  name: 'feedback-tab',
  components: { InfiniteLoading, Feedback },
  props: {
    projectId: null,
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      feedback: {
        projectHealth: '',
        details: '',
      },
      currentUserEmail: '',
      displayAllFeedbacks: true,
      feedbackOptions: [
        {
          name: 'on-track',
          projectHealth: 'on-track',
          color: 'green',
          class: 'text-success',
        },
        {
          name: 'at-risk',
          projectHealth: 'at-risk',
          color: 'orange',
          class: 'text-warning',
        },
        {
          name: 'off-track',
          projectHealth: 'off-track',
          color: 'red',
          class: 'text-danger',
        },
      ],
    };
  },
  computed: {
    getHealthClass() {
      return utilsGetHealthClassFromFeedbackValue(
        this.feedback.projectHealth || '',
      );
    },
    getCurrentUserEmail() {
      return this.$store.getters['users/GET_CURRENT_USER_EMAIL'];
    },
    feedbacks() {
      return this.$store.getters['feedbacks/GET_FEEDBACKS'];
    },
    infiniteId() {
      return this.$store.getters['feedbacks/GET_INFINITE_ID'];
    },
    showFeedbackForm() {
      return !this.isReadOnly;
    },
    feedbackIsSelected() {
      return this.feedback.projectHealth !== '';
    },
  },
  watch: {
    displayAllFeedbacks(isFeedbackDisplayed) {
      if (isFeedbackDisplayed) {
        this.currentUserEmail = '';
      } else {
        this.currentUserEmail = this.getCurrentUserEmail;
      }
      this.resetFeedbacks();
    },
    projectId(newValue, oldValue) {
      this.resetFeedbacks();
    },
  },
  created() {
    // On created
    this.resetFeedbacks();
  },
  mounted() {
    // On mounted
    this.resetFeedback();
  },
  methods: {
    onSave() {
      if (this.feedbackIsSelected) {
        this.sendFeedback();
      }
    },
    sendFeedback() {
      this.$emit('sendFeedback', this.feedback);
      this.resetFeedback();
    },
    resetFeedback() {
      this.feedback = {
        projectHealth: '',
        details: '',
      };
    },
    resetFeedbacks() {
      this.$store.dispatch('feedbacks/resetFeedbacks');
    },
    infiniteHandler(infiniteState) {
      const payload = {
        userEmail: this.currentUserEmail,
        projectId: this.projectId,
        infiniteState,
        vm: this,
      };
      this.$store.dispatch('feedbacks/fetchProjectFeedbacks', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin define-color($colorName, $colorValue) {
  .bg-#{$colorName} {
    &::v-deep .md-list-item-button:hover {
      background-color: $colorValue !important;
    }
    &::v-deep .ti-tag {
      background-color: $colorValue !important;
    }
  }

  .md-selected.bg-#{$colorName} {
    &::v-deep .md-list-item-button {
      background-color: $colorValue;
    }
  }
}
.feedbacks-stream-container {
  align-content: flex-start;
}
.feedbacks-stream {
  padding: 0.8rem 0rem 0.5rem;
}

.icon-status-container {
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.feedback-action-container {
  width: 100%;
}

.actions-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  padding: 0rem;
}

.feedback-form {
  flex-grow: 2.5;
}

.feedback-form-container {
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.feedback-form-container, .feedback-form {
  display: flex;
}
.feedback-form-container > div, .feedback-form > div {
  padding-left: 5px;
  padding-right: 5px;
}

.feedback-status-select {
  width: 100px;
}

.detail-field {
  width: auto;
}

.detail-field-container {
  min-width: 150px;
  width: 100%;
}

.feedback-display-options {
  padding-left: 15px;
}

@include define-color("green", $avalia-green);
@include define-color("orange", $avalia-orange);
@include define-color("red", $avalia-red);
</style>
