<template>
  <modal @close="hide">
    <template slot="header">
      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="hide"
      >
        <md-icon>clear</md-icon>
      </md-button>
      <h4 class="modal-title">{{ title }}</h4>
    </template>

    <template slot="body">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100">
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ passed, failed }"
                ><md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <!-- Project Name -->
                  <label>Project Name</label>
                  <md-input
                    v-model="currentProject.name"
                    type="text"
                    ref="projectNameField"
                    required
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <!-- Description -->
            <div
              class="md-layout-item md-size-100 mx-auto md-medium-size-100 description-field"
            >
              <md-field>
                <label>Description</label>
                <md-textarea
                  :maxlength="2000"
                  :md-autogrow="true"
                  v-model="currentProject.description"
                  type="text"
                />
              </md-field>
            </div>
            <!-- Google Drive Link -->
            <div
              class="md-layout-item md-size-100 mx-auto md-medium-size-100 link-field"
            >
              <md-field>
                <md-icon><i class="fab fa-google-drive"></i></md-icon>
                <label>Google Drive Link</label>
                <md-input v-model="currentProject.driveLink" type="text" />
              </md-field>
            </div>
            <!-- Google Chat Link -->
            <div
              class="md-layout-item md-size-100 mx-auto md-medium-size-100 link-field"
            >
              <md-field>
                <md-icon><i class="fas fa-comments"></i></md-icon>
                <label>Google Chat Link</label>
                <md-input v-model="currentProject.chatLink" type="text" />
              </md-field>
            </div>
            <!-- Tags -->
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100">
              <div class="md-layout tags-field-container">
                <label
                  class="md-layout-item md-size-10 md-form-label tags-label"
                  >Tags</label
                >
                <div class="md-layout-item md-size-90 tags-input">
                  <md-field class="tags-field">
                    <vue-tags-input
                      class="avalia-tags-input"
                      v-model="tag"
                      :tags="getProjectTags"
                      :autocomplete-items="autocompleteItems"
                      :placeholder="tagsInputPlaceholder"
                      @tags-changed="tagsChanged"
                      @before-adding-tag="newTagToValidate"
                    />
                  </md-field>
                </div>
              </div>
            </div>
            <!-- Archived -->
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100">
              <md-checkbox v-model="currentProject.isArchived"
                >Archived</md-checkbox
              >
            </div>

            <div
              class="md-layout-item md-size-100 mx-auto md-medium-size-100 buttons-container"
            >
              <md-button
                class="md-danger modal-button md-sm cancel-button"
                @click="hide"
                >Cancel</md-button
              >
              <md-button class="md-success md-sm confirm-button" type="submit">
                Confirm
              </md-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import _ from 'lodash';

import { SlideYDownTransition } from 'vue2-transitions';
import VueTagsInput from '@johmun/vue-tags-input';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import api from '@/api/api';
import { Modal } from '@/components/Template';
import notify from '@/utils/notificationsUtils';

extend('required', required);

export default {
  name: 'edit-project-modal',
  components: { Modal, SlideYDownTransition, VueTagsInput },
  props: {
    project: Object,
  },
  data() {
    return {
      title: 'Edit Project Information',
      currentProject: {},
      tagType: 'project tag',
      tagsInputPlaceholder: '',
      tag: '',
      autocompleteItems: [],
      debounce: null,
    };
  },
  computed: {
    allTags() {
      return this.$store.getters['tags/GET_PROJECT_TAGS'];
    },
    getProjectTags() {
      return this.currentProject.tags
        ? this.currentProject.tags.map((t) => ({ text: t.name }))
        : [];
    },
  },
  watch: {
    tag: 'loadTags',
  },
  created() {
    this.currentProject = _.cloneDeep(this.project);
  },
  mounted() {
    this.fetchTags();
  },
  methods: {
    // Tags methods
    loadTags() {
      if (this.tag.length < 1) return;

      const url = `/tags?name=${this.tag.toLowerCase()}&type=${this.tagType}`;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        api.assess
          .get(url)
          .then((response) => {
            this.autocompleteItems = response.data.map((t) => ({
              text: t.name,
            }));
          })
          .catch((error) => {
            // console.log(error);
            const errorMessage = `Could not Fetch Tags : ${error.response.data.message || ''}`;
            notify.notifyError(errorMessage, this);
          });
      }, 500);
    },
    tagsChanged(newTags) {
      this.autocompleteItems = [];
      this.currentProject.tags = newTags.map((t) => ({ name: t.text }));
    },
    newTagToValidate(obj) {
      const tag = obj.tag.text.toLowerCase();
      if (!this.allTags.map((t) => t.name).includes(tag)) {
        this.createTagRecord(tag);
      }
      if (!this.currentProject.tags.includes(tag)) {
        obj.addTag();
      }
      this.tag = '';
    },
    createTagRecord(tag) {
      const tagPayload = { name: tag, type: this.tagType };
      api.assess
        .post('/tags', tagPayload)
        .then((response) => {
          this.fetchTags();
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Add Tag : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    fetchTags() {
      this.$store.dispatch('tags/fetchProjectTags', this);
    },
    // Other methods
    hide() {
      this.$emit('close');
    },
    onSubmit() {
      this.$emit('editProject', this.currentProject);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-required {
  text-align: left;
  padding-left: 1rem;
  padding-top: 0.5rem;
  font-size: 14px;
}
.buttons-container {
  padding-top: 0.4rem;
}
.tags-field-container {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.tags-field {
  margin-top: 0rem;
}
.tags-label {
  padding-top: 0.8rem;
  padding-left: 0rem;
}
.tags-input {
  padding-left: 0rem;
}
.modal-button {
  margin-right: 0.4rem;
}
.link-field {
  padding-top: 0.4rem;
}
.description-field {
  padding-bottom: 0.4rem;
}
// CUSTOM BUTTONS TO MOVE TO avalia.scss
.confirm-button {
  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }
}
.cancel-button {
  color: #fe0600 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #fe0600 !important;
    opacity: 0.8 !important;
    border-color: #fe0600 !important;
  }
}
</style>
