<template>
  <div>
    <milestones-time-line plain :type="simpleTimeline">
      <milestones-time-line-item
        inverted
        v-for="milestone in milestones"
        :key="milestone.definitionId"
        :badgeType="getMilestoneType(milestone)"
        :badgeIcon="getMilestoneIcon(milestone)"
      >
        <milestone-badge slot="header" :type="getMilestoneType(milestone)">{{
          milestone.name
        }}</milestone-badge>
      </milestones-time-line-item>
    </milestones-time-line>
  </div>
</template>

<script>
// import { TimeLine, TimeLineItem, Badge } from "@/components";
import MilestonesTimeLine from '@/components/Avalia/ProjectDetails/Milestones/MilestonesTimeLine.vue';
import MilestonesTimeLineItem from '@/components/Avalia/ProjectDetails/Milestones/MilestonesTimeLineItem.vue';
import MilestoneBadge from '@/components/Avalia/ProjectDetails/Milestones/MilestoneBadge.vue';

export default {
  name: 'milestones-time-line-view',
  components: {
    MilestonesTimeLine,
    MilestonesTimeLineItem,
    MilestoneBadge,
  },
  props: {
    milestones: Array,
  },
  data() {
    return {
      responsive: false,
    };
  },
  computed: {
    simpleTimeline() {
      if (this.responsive) {
        return 'simple';
      }
      return '';
    },
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
    getMilestoneType(milestone) {
      let type;
      if (milestone.next) {
        type = 'info';
      } else {
        type = milestone.completionDate ? 'success' : 'primary';
      }
      return type;
    },
    getMilestoneIcon(milestone) {
      let icon;
      if (milestone.next) {
        icon = 'hourglass_empty';
      } else {
        icon = milestone.completionDate ? 'done' : 'lock';
      }
      return icon;
    },
  },
};
</script>

<style lang="scss" scoped></style>
