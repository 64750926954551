<template>
  <div>
    <div class="info-container"><shortcuts-info /></div>
    <notes-container
      :notes="notes"
      @onCreateNote="onCreateNote"
      @onUpdateNote="onUpdateNote"
      @onDeleteNote="onDeleteNote"
    />
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import notesService from '@/services/notesService';
import NotesContainer from '@/components/Avalia/Note/NotesContainer.vue';
import ShortcutsInfo from './ShortcutsInfo.vue';

export default {
  name: 'notes-project-container',
  components: {
    NotesContainer,
    ShortcutsInfo,
  },
  computed: {
    projectId() {
      return this.$store.getters['project/GET_PROJECT_ID'];
    },
    projectName() {
      return this.$store.getters['project/GET_PROJECT_NAME'];
    },
    notes() {
      return this.$store.getters['notes/GET_PROJECT_NOTES'];
    },
  },
  data() {
    return {
    };
  },
  created() {
    this.fetchProjectNotes();
  },
  methods: {
    fetchProjectNotes() {
      const payload = {
        projectId: this.projectId,
        vm: this,
      };
      return this.$store.dispatch('notes/fetchProjectNotes', payload);
      /*
      const url = `/projects/${this.projectId}/notes`;
      return api.assess
        .get(url)
        .then((response) => {
          this.notes = response.data;
        })
        .catch((error) => {
          const errorMessage = `Could not Fetch Project Notes : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
        */
    },
    onCreateNote(notePayload) {
      const createPayload = {
        ...notePayload,
        metadata: [
          {
            key: 'relatedElement',
            value: 'project',
          },
          {
            key: 'relatedElementLink',
            value: `/project/${this.projectId}`,
          },
          {
            key: 'relatedElementName',
            value: this.projectName,
          },
        ],
      };
      api.assess
        .post(`/projects/${this.projectId}/notes`, createPayload)
        .then((response) => {
          this.fetchProjectNotes();
          const successMessage = 'Note successfully Saved';
          notify.notifySuccess(successMessage, this);
        })
        .catch((error) => {
          const errorMessage = `Could not Save Note for Project ${this.projectName} : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    onUpdateNote(notePayload) {
      return notesService.updateNote(notePayload, this)
        .then(() => this.fetchProjectNotes());
    },
    onDeleteNote(notePayload) {
      return notesService.deleteNote(notePayload, this)
        .then(() => this.fetchProjectNotes());
    },
  },
};
</script>

<style lang="scss" scoped>
.notes-container{
  padding-right: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
}
.info-container {
  display: flex;
  justify-content: flex-end;
}
</style>
