<template>
<div>
  <div v-if="loading">
    <loader/>
  </div>
  <div v-else>
    <edit-project-modal
      v-if="showEditProjectModal"
      :project="project"
      @close="showEditProjectModal = false"
      @editProject="onEditProject"
      class="edit-project-modal"
    >
    </edit-project-modal>
    <add-participants-modal
      v-if="showAddParticipantsModal"
      :projectId="projectId"
      :existingUsers="existingUsers"
      :existingProjectRoles="existingProjectRoles"
      :team="project.users"
      @close="showAddParticipantsModal = false"
      class="edit-team-modal"
    >
    </add-participants-modal>
    <template v-if="isExternalUsersFeatureEnabled">
      <invite-participants-modal
        v-if="showInviteParticipantsModal"
        :projectId="projectId"
        :existingProjectRoles="existingProjectRoles"
        :team="project.users"
        @close="showInviteParticipantsModal = false"
        class="edit-team-modal"
      >
      </invite-participants-modal>
    </template>
    <role-manager
      :open="showEditRolesModal"
      :rolesOptions="existingProjectRoles"
      :user="editedParticipant"
      :projectId="projectId"
      @close="showEditRolesModal = false"
      @editRoles="onEditRoles"
    />
    <create-task-modal
      v-if="showCreateTaskModal"
      :projectId="projectId"
      @createTask="onCreateTask"
      @close="showCreateTaskModal = false"
      class="create-task-modal"
    >
    </create-task-modal>
    <div class="md-layout">
      <div class="md-layout-item md-size-100 md-medium-size-100">
        <md-card class="header-card-margin">
          <md-card-content>
            <div
              class="md-layout md-alignment-center-space-between"
            >
              <div class="md-layout-item md-size-25 project-state">
                <md-icon class="md-size-3x text-info state-icon"
                  ><i class="fa" :class="getStateIcon"></i>
                  <md-tooltip md-direction="top" v-html="getStateTooltip"></md-tooltip>
                </md-icon>
                <h6 class="state-label">
                  {{ project.currentState || "completed" }}
                </h6>
              </div>
              <div class="md-layout-item md-size-50 project-name">
                <h2 class="title project-title">
                  {{ project && project.name ? project.name.toUpperCase() : "" }}
                </h2>
                <h6 class="type-label">
                  {{ project && project.caseType && project.caseVersion ? getProjectType : "" }}
                </h6>
              </div>
              <div class="md-layout-item md-size-25 project-health">
                <h6 class="health-label">{{ project.health }}</h6>
                <md-icon class="md-size-3x" :class="getHealthClass">
                  <i class="fa fa-heartbeat"></i
                  ><md-tooltip md-direction="top" v-html="getHealthTooltip"></md-tooltip
                ></md-icon>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>

      <!-- Left Column with Tasks and Milestones Cards -->

      <div class="md-layout-item md-xlarge-size-25 md-large-size-30 md-medium-size-100">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 nopadding">
            <md-card>
              <md-card-header class="md-card-header-text">
                <div class="card-text">
                  <h5 class="title">Milestones</h5>
                </div>
              </md-card-header>
              <md-card-content class="custom-card-content">
                <milestones-time-line-view
                  :milestones="project.milestones"
                ></milestones-time-line-view>
              </md-card-content>
            </md-card>
          </div>
          <div class="md-layout-item md-size-100 nopadding">
            <tasks-card
              :tasksAvailable="project.tasks"
              :tasksCompleted="completedTasks"
              :tasksLocked="lockedTasks"
              :title="taskCardTitle"
              :isReadOnly="hasOnlyObserverRole"
              @workOnTask="goToTaskDetails"
              @startTask="onStartTask"
              @viewTask="goToTaskDetails"
              @createTask="showCreateTaskModal = true"
            >
            </tasks-card>
          </div>
        </div>
      </div>

      <!-- Central Column with Interactions (Event Feed, Feedback, Comment) -->

      <div class="md-layout-item md-xlarge-size-50 md-large-size-40 md-medium-size-100">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 nopadding">
            <div>
              <!-- <md-card-header><h5 class="title">Events</h5></md-card-header> -->
              <div class="interactions-card-content">
                <interactions-view
                  :project="project"
                  :projectId="projectId"
                  :isReadOnly="hasOnlyObserverRole"
                  @sendFeedback="onSendFeedback"
                ></interactions-view>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Column with Data and Team Cards -->

      <div class="md-layout-item md-xlarge-size-25 md-large-size-30 md-medium-size-100">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 nopadding">
            <div class="explorer-container">
              <router-link :to="projectTasksExplorerLink">
              <div class="explorer">
                <md-icon>view_module</md-icon>
                <span>Tasks Explorer</span>
                <md-tooltip md-direction="top">Coming soon</md-tooltip>
              </div>
              </router-link>
              <router-link :to="projectNotesExplorerLink">
                <div class="explorer">
                  <md-icon>view_list</md-icon>
                  <span>Notes Explorer</span>
                </div>
              </router-link>
            </div>
          </div>
          <div class="md-layout-item md-size-100 nopadding">
            <md-card>
              <md-card-header class="md-card-header-text">
                <div class="card-text">
                  <h5 class="title">Summary</h5>
                </div>

                <div v-if="displaySummaryEditButtons" class="card-control-button">
                  <span @click="showEditProjectModal = true">
                    <md-icon class="icon-action icon-edit">edit</md-icon>
                    <md-tooltip md-delay="300">Edit Project information</md-tooltip>
                  </span>
                </div>
              </md-card-header>
              <md-card-content class="custom-card-content">
                <data-view :project="project"></data-view>
              </md-card-content>
            </md-card>
          </div>

          <div class="md-layout-item md-size-100 nopadding">
            <md-card>
              <md-card-header class="md-card-header-text">
                <div class="card-text">
                  <h5 class="title">Team</h5>
                </div>

                <div v-if="hasCuratorRole" class="card-control-button">

                  <template v-if="isExternalUsersFeatureEnabled">
                    <span @click="showInviteParticipantsModal = true">
                      <md-icon class="icon-action icon-edit">mail</md-icon>
                      <md-tooltip md-delay="300">Invite external to project</md-tooltip>
                    </span>
                  </template>
                  <span @click="showAddParticipantsModal = true">
                    <md-icon class="icon-action icon-edit">person_add</md-icon>
                    <md-tooltip md-delay="300">Add participants to project</md-tooltip>
                  </span>
                </div>
              </md-card-header>
              <md-card-content class="custom-card-content">
                <team-view
                :team="project.users"
                :isReadOnly="displayTeamEditButton"
                @editParticipant="onEditParticipant"
                @deleteParticipant="onDeleteParticipant"></team-view>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import api from '@/api/api';
import Loader from '@/components/Avalia/Loader/Loader.vue';
import TasksCard from '@/components/Avalia/ProjectDetails/Tasks/TasksCard.vue';
import MilestonesTimeLineView from '@/components/Avalia/ProjectDetails/Milestones/MilestonesTimeLineView.vue';
import DataView from '@/components/Avalia/ProjectDetails/Data/DataView.vue';
import TeamView from '@/components/Avalia/ProjectDetails/Team/TeamView.vue';
import InteractionsView from '@/components/Avalia/ProjectDetails/Interactions/InteractionsView.vue';
import EditProjectModal from '@/components/Avalia/Modals/EditProjectModal.vue';
import AddParticipantsModal from '@/components/Avalia/Modals/AddParticipantsModal.vue';
import InviteParticipantsModal from '@/components/Avalia/Modals/InviteParticipantsModal.vue';
import CreateTaskModal from '@/components/Avalia/Modals/CreateTaskModal.vue';
import RoleManager from '@/components/Avalia/TeamManager/RoleManager.vue';
import { utilsGetHealthClass } from '@/utils/utils';
import {
  observerRole, curatorRole, avaliaExpertRole, externalExpertRole,
} from '@/utils/rolesUtils';
import notify from '@/utils/notificationsUtils';
import Swal from 'sweetalert2';

const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: 'md-button md-success btn-fill',
    cancelButton: 'md-button md-danger btn-fill',
  },
  buttonsStyling: false,
});

export default {
  name: 'project-details',
  components: {
    TasksCard,
    MilestonesTimeLineView,
    DataView,
    TeamView,
    InteractionsView,
    EditProjectModal,
    AddParticipantsModal,
    InviteParticipantsModal,
    CreateTaskModal,
    RoleManager,
    Loader,
  },
  data() {
    return {
      projectId: null,
      showEditProjectModal: false,
      showAddParticipantsModal: false,
      showInviteParticipantsModal: false,
      showEditRolesModal: false,
      showCreateTaskModal: false,
      editedParticipant: null,
      taskToWorkOn: {},
      taskCardTitle: 'Tasks',
      loading: true,
    };
  },
  computed: {
    isExternalUsersFeatureEnabled() {
      return this.$store.getters['config/GET_CORE_CONFIG'].FF_EXTERNAL_USERS;
    },
    // Possible refactor in utils file since this is used in ProjectOverviewCard as well?
    getHealthClass() {
      return utilsGetHealthClass(this.project.health);
    },
    getHealthTooltip() {
      return `Project health</br><b>${this.project.health}<b>`;
    },
    getStateIcon() {
      return this.project.currentStateIcon || 'fa-trophy';
    },
    getStateTooltip() {
      return `Project state</br><b>${this.project.currentState || 'completed'}<b>`;
    },
    existingUsers() {
      return this.$store.getters['users/GET_USERS'];
    },
    existingProjectRoles() {
      return this.$store.getters['projects/GET_POSSIBLE_ROLES'];
    },
    project() {
      return this.$store.getters['project/GET_PROJECT'];
    },
    getProjectType() {
      return `${this.project.caseType} v${this.project.caseVersion}`;
    },
    completedTasks() {
      return this.$store.getters['project/GET_COMPLETED_TASKS'];
    },
    lockedTasks() {
      return this.$store.getters['project/GET_LOCKED_TASKS'];
    },
    currentUser() {
      return this.$store.getters['users/GET_CURRENT_USER'];
    },
    currentUserInProject() {
      return this.project.users.find((u) => u.email === this.currentUser.sub);
    },
    hasCuratorRole() {
      return this.hasRoleInProject(curatorRole);
    },
    hasAvaliaExpertRole() {
      return this.hasRoleInProject(avaliaExpertRole);
    },
    hasExternalExpertRole() {
      return this.hasRoleInProject(externalExpertRole);
    },
    hasObserverRole() {
      return this.hasRoleInProject(observerRole);
    },
    hasOnlyObserverRole() {
      if (this.hasCuratorRole || this.hasAvaliaExpertRole || this.hasExternalExpertRole) {
        return false;
      }
      return true;
    },
    displaySummaryEditButtons() {
      return !this.hasOnlyObserverRole;
    },
    displayTeamEditButton() {
      return !this.hasCuratorRole;
    },
    projectNotesExplorerLink() {
      return `/project/${this.projectId}/notes`;
    },
    projectTasksExplorerLink() {
      // When Task Explorer done (WIP)
      // return `/project/${this.projectId}/tasks`;

      // WIP - just stay in project details for now
      return `/project/${this.projectId}`;
    },
  },
  watch: {
    '$route.params.projectId': {
      handler(newValue, oldValue) {
        this.projectId = +this.$route.params.projectId;
        if (this.project.id !== this.projectId) {
          this.fetchAllProjectData();
        }
      },
      immediate: false,
    },
  },
  created() {
    // On created
    this.projectId = +this.$route.params.projectId;
    this.fetchAllProjectData();
  },
  methods: {
    fetchAllProjectData() {
      this.loading = true;
      const projectPromise = this.fetchProjectDetails(this.projectId);
      const completedTasksPromise = this.fetchProjectCompletedTasks(this.projectId);
      const lockedTasksPromise = this.fetchProjectLockedTasks(this.projectId);
      const rolesPromise = this.fetchAllProjectRoles();
      const usersPromise = this.fetchUsers();
      Promise.all([projectPromise, completedTasksPromise, lockedTasksPromise, rolesPromise, usersPromise])
        .then(() => {
          this.loading = false;
        });
    },
    hasRoleInProject(role) {
      return this.currentUserInProject.roles.includes(role);
    },
    fetchUsers() {
      return this.$store.dispatch('users/fetchUsers', this);
    },
    fetchAllProjectRoles() {
      return this.$store.dispatch('projects/fetchAllProjectRoles', this);
    },
    fetchProjectDetails(id) {
      return this.$store.dispatch('project/fetchProjectDetails', { projectId: id, vm: this });
    },
    fetchProjectCompletedTasks(id) {
      return this.$store.dispatch('project/fetchProjectCompletedTasks', { id, vm: this });
    },
    fetchProjectLockedTasks(id) {
      return this.$store.dispatch('project/fetchProjectLockedTasks', { id, vm: this });
    },
    resetEvents() {
      this.$store.dispatch('events/resetEvents');
    },
    resetFeedbacks() {
      this.$store.dispatch('feedbacks/resetFeedbacks');
    },
    onStartTask(task) {
      this.$store.dispatch('project/startProjectTask', { taskId: task.id, vm: this });
    },
    goToTaskDetails(task) {
      this.$router.push({
        name: 'Task Details',
        params: { projectId: this.projectId, taskId: task.id },
      });
    },
    onEditParticipant(participation) {
      this.editedParticipant = participation;
      this.showEditRolesModal = true;
    },
    onDeleteParticipant(participation) {
      swalWithCustomButtons
        .fire({
          title: 'Are you sure?',
          text: 'Do you want to remove this participation?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.deleteParticipant(participation);
          }
        });
    },
    deleteParticipant(participation) {
      const deletionPayload = {
        projectId: this.projectId,
        userEmail: participation.email,
      };
      api.assess
        .delete('/participations', { data: deletionPayload })
        .then((response) => {
          this.fetchProjectDetails(this.projectId);
          this.resetEvents();
          const successMessage = 'Participation successfully Removed';
          notify.notifySuccess(successMessage, this);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Remove Participation: ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    onEditRoles(participations) {
      api.assess
        .put('/participations', participations)
        .then((response) => {
          // We have to wait for project to be updated before filtering user options
          this.fetchProjectDetails(this.projectId);
          this.resetEvents();
          this.showEditRolesModal = false;
          const successMessage = 'Participation Roles successfully Edited';
          notify.notifySuccess(successMessage, this);
        })
        .catch((error) => {
          const errorMessage = `Could not Edit Roles : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    onEditProject(project) {
      const payload = {
        name: project.name,
        description: project.description,
        driveLink: project.driveLink,
        chatLink: project.chatLink,
        tags: project.tags,
        isArchived: project.isArchived,
      };
      api.assess
        .patch(`/projects/${this.projectId}`, payload)
        .then((response) => {
          this.fetchProjectDetails(this.projectId);
          this.resetEvents();
          this.showEditProjectModal = false;
          const successMessage = 'Project information successfully updated';
          notify.notifySuccess(successMessage, this);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not edit project : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    onSendFeedback(feedback) {
      const payload = {
        projectHealth: feedback.projectHealth,
        details: feedback.details,
        projectId: this.projectId,
      };
      api.assess
        .post('/feedbacks', payload)
        .then((response) => {
          this.fetchProjectDetails(this.projectId);
          this.resetFeedbacks();
          this.resetEvents();
          const successMessage = 'Feedback successfully sent';
          notify.notifySuccess(successMessage, this);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not send feedback : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    onCreateTask(task) {
      api.assess
        .post('/tasks', task)
        .then((response) => {
          this.fetchProjectDetails(this.projectId);
          this.resetEvents();
          this.showCreateTaskModal = false;
          const successMessage = 'Task successfully created';
          notify.notifySuccess(successMessage, this);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not create task : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.project-name {
  text-align: center;
}
.project-state {
  display: -webkit-box;
}
.project-health {
  display: -webkit-box;
  -webkit-box-pack: end;
}
.header-layout-item-padding {
  padding: 0vh 0.2rem 0vh;
}
.header-card-margin {
  margin-top: 0rem;
}
.card-control-button {
  position: absolute;
  top: 8px;
  right: 10px;
}
.custom-card-content {
  overflow: auto;
}
.interactions-card-content {
  overflow: hidden;
  padding-top: 0rem;
  margin: 0rem 0.3rem;
}
.state-label {
  padding: 0rem 0rem 0rem 1.5rem;
  margin: 2rem 0rem 0rem 0rem;
  font-size: 1rem;
}
.type-label {
  margin: 0rem 0rem 0rem 0rem;
}
.health-label {
  padding: 0rem 1.5rem 0rem 0rem;
  margin: 2rem 0rem 0rem 0rem;
  font-size: 1rem;
}
.edit-team-modal {
  z-index: 8;
}
.explorer-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}
.explorer {
  display: flex;
  flex-direction: column;
}
</style>
