<template>
  <div>
    <div class="is-archived-container" v-if="project.isArchived">
      <div class="archived-icon-container">
        <i class="fas fa-archive" />
      </div>
      <h6>Archived</h6>
    </div>
    <div class="timestamp-container">
      <h6 class=" description-title">Updated {{ project.lastUpdate | moment("from") }}</h6>
    </div>
    <!-- Tags -->
    <div class="tags-container">
      <vue-tags-input
        class="avalia-tags-input no-close-icon hide-tags-input"
        v-model="tag"
        :placeholder="tagsInputPlaceholder"
        :tags="getTags"
        :disabled="true"
      />
    </div>
    <!-- Project Resources Link -->
    <div class="resources-container">
      <h6 class=" description-title">External resources</h6>
      <div class="link-container">
        <md-icon class="resource-icon"><i class="fab fa-google-drive"></i></md-icon>
        <div v-if="hasDriveLink">
          <a :href="`${project.driveLink}`" target="_blank">Google Drive</a>
        </div>
        <div v-else>
          <p>No Google Drive</p>
        </div>
      </div>
      <!-- Project Discussion Link -->
      <div class="link-container">
        <md-icon class="resource-icon"><i class="fas fa-comments"></i></md-icon>
        <div v-if="hasChatLink">
          <a :href="`${project.chatLink}`" target="_blank">Google Chat</a>
        </div>
        <div v-else>
          <p>No Google Chat</p>
        </div>
      </div>
    </div>
    <!-- Description -->
    <div class="description-container">
      <div class="description">
        <h6 class=" description-title">Description</h6>
        <p class="description">
          {{ project.description || "-" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  name: 'data-view',
  components: { VueTagsInput },
  props: {
    project: Object,
  },
  data() {
    return {
      tag: '',
      tagsInputPlaceholder: '',
    };
  },
  computed: {
    getTags() {
      return this.project.tags ? this.project.tags.map((t) => ({ text: t.name })) : [];
    },
    hasDriveLink() {
      return this.project.driveLink && this.project.driveLink.length > 0;
    },
    hasChatLink() {
      return this.project.chatLink && this.project.chatLink.length > 0;
    },
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.timestamp-container {
  display: -webkit-box;
  justify-content: flex-start;
  padding: 0.4rem 0rem 0rem 0rem;
  .md-icon {
    padding: 0rem 1rem 0rem 0.7rem;
    margin-left: -0.3rem;
  }
  .timestamp {
    padding: 0rem 0.5rem;
  }
}
.description-container {
  display: -webkit-box;
  padding: 0rem 0rem 0rem;
}
.description-title {
  margin: 1rem 0rem 0rem 0rem;
}
.description {
  color: black;
}
.is-archived-container {
  display: -webkit-box;
  justify-content: flex-start;
  .archived-icon-container {
    padding-top: 0rem;
  }
  h6 {
    margin: 0rem 0.4rem 0.4rem;
    font-size: 1rem;
  }
}
.resources-container {
  padding: 0rem 0rem 1rem 0rem;
}
.tags-container {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin-left: -0.3rem;
}
.link-container {
  padding-top: 0.4rem;
  padding-left: 0.8rem;
  display: -webkit-box;
  flex-direction: row;
  justify-content: flex-start;
  p {
    margin: 0rem;
  }
}
.resource-icon {
  padding: 0rem 1.4rem 0rem 0rem;
}
</style>
