<template>
  <md-card class="md-card-timeline" :class="{ 'md-card-plain': plain }">
    <ul
      class="milestones-timeline"
      :class="{ 'timeline-simple': type === 'simple' }"
    >
      <slot></slot>
    </ul>
  </md-card>
</template>
<script>
export default {
  name: 'MilestoneTimeLine',
  props: {
    plain: Boolean,
    type: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.md-card-timeline {
  margin: 0px 0px;
}
.milestones-timeline {
  padding: 0px 0px;
  margin: 0px 0px;
}
.milestones-timeline:before {
  top: 38px !important;
  bottom: 20px !important;
}
</style>
