const observerRole = 'observer';
const curatorRole = 'curator';
const avaliaExpertRole = 'avalia expert';
const externalExpertRole = 'external expert';

export {
  observerRole,
  curatorRole,
  avaliaExpertRole,
  externalExpertRole,
};
