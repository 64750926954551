<template>
  <div ref="teamView">
    <md-table v-model="team">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Name"
          >{{ item.displayName }}
          <md-tooltip md-direction="top">{{
            item.email
          }}</md-tooltip></md-table-cell
        >
        <md-table-cell :md-label="feedbackLabel" class="status-cell"
          ><md-icon :class="getHealthClass(item.status)">
            <i class="fa fa-heartbeat"></i></md-icon
          ><md-tooltip
            md-direction="top"
            v-html="getHealthTooltip(item.status)"
          ></md-tooltip
        ></md-table-cell>
        <md-table-cell md-label="Roles">{{
          rolesAsString(item.roles)
        }}</md-table-cell>

        <md-table-cell class="actions-cell" v-if="displayRoleEditButtons">
          <div v-if="isSmallScreen">
            <md-menu
              md-size="auto"
              md-direction="top-start"
              :mdCloseOnClick="true"
            >
              <md-button class="md-icon-button md-round" md-menu-trigger>
                <md-icon>more_vert</md-icon>
              </md-button>

              <md-menu-content>
                <div class="action-text-container">
                  <div @click="editParticipant(item)">
                    Edit Roles
                  </div>
                  <md-divider class="divider"></md-divider>
                  <div @click="deleteParticipant(item)">
                    Delete participant
                  </div>
                </div>
              </md-menu-content>
            </md-menu>
          </div>
          <div class="big-screen" v-else>
            <span class="participant-edit-buttons">
              <span @click="editParticipant(item)">
                    <md-icon class="icon-action icon-edit">edit</md-icon>
                    <md-tooltip md-delay="300">Edit Participant roles</md-tooltip>
              </span>
              <span @click="deleteParticipant(item)">
                    <md-icon class="icon-action icon-delete">delete</md-icon>
                    <md-tooltip md-delay="300">Delete Participant</md-tooltip>
              </span>
            </span>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { utilsGetHealthClassFromFeedbackValue } from '@/utils/utils';

export default {
  name: 'team-view',
  components: {},
  props: {
    team: {
      type: Array,
      default: () => [],
    },
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isSmallScreen: false,
    };
  },
  computed: {
    displayRoleEditButtons() {
      return !this.isReadOnly;
    },
    feedbackLabel() {
      return this.isSmallScreen ? '' : 'Feedback';
    },
  },
  created() {
    // On created
  },
  mounted() {
    // On mounted
    this.checkAndSetIsSmallScreen();
  },
  watch: {},
  methods: {
    rolesAsString(roles) {
      return roles
        ? roles.map((role) => role[0].toUpperCase() + role.slice(1)).join(', ')
        : '';
    },
    getStatus(status) {
      return status ? status.health : '-';
    },
    editParticipant(participant) {
      this.$emit('editParticipant', participant);
    },
    deleteParticipant(participant) {
      this.$emit('deleteParticipant', participant);
    },
    getHealthClass(status) {
      return utilsGetHealthClassFromFeedbackValue(
        status && status.health ? status.health : '',
      );
    },
    getHealthTooltip(status) {
      return `Last feedback</br><b>${
        status && status.health ? status.health : 'none'
      }<b>`;
    },
    checkAndSetIsSmallScreen() {
      this.isSmallScreen = this.$refs.teamView.clientWidth < 300;
    },
  },
};
</script>

<style lang="scss" scoped>
.status-cell {
  text-align: left;
  padding-left: 1rem;
}
.participant-edit-buttons {
  display: flex;
  justify-content: space-between;
}
.delete-button {
  margin-left: 0.5rem;
}
.action-text-container {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.divider {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
