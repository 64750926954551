import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

function updateNote(notePayload, vm) {
  return api.assess
    .put(`/notes/${notePayload.id}`, notePayload)
    .then(() => {
      const successMessage = 'Note successfully Updated';
      notify.notifySuccess(successMessage, vm);
    })
    .catch((error) => {
      const errorMessage = `Could not Update Note : ${error.response.data.message || ''}`;
      notify.notifyError(errorMessage, vm);
    });
}

function deleteNote(notePayload, vm) {
  return api.assess
    .delete(`/notes/${notePayload.id}`)
    .then(() => {
      const successMessage = 'Note successfully Deleted';
      notify.notifySuccess(successMessage, vm);
    })
    .catch((error) => {
      const errorMessage = `Could not Delete Note : ${error.response.data.message || ''}`;
      notify.notifyError(errorMessage, vm);
    });
}

export default {
  updateNote,
  deleteNote,
};
