<template>
  <div
    class="md-layout"
    @keydown.ctrl.down.prevent.stop="focusNextNote"
    @keydown.meta.down.prevent.stop="focusNextNote"
    @keydown.ctrl.up.prevent.stop="focusPreviousNote"
    @keydown.meta.up.prevent.stop="focusPreviousNote"
    @keydown.ctrl.enter.prevent.stop="showFocusAndScrollToNewNote"
    @keydown.meta.enter.prevent.stop="showFocusAndScrollToNewNote"
  >
    <div v-for="(note, index) in notes" :key="index" class="notes-container md-layout-item md-size-100">
      <note
          :note="note"
          @saveNote="onSaveNote"
          @deleteNote="onDeleteNote"
          @hasBeenFocused="haveBeenFocused(note)"
          :isFocused="isNoteFocused(note)"
          :ref="notes"
          :hideInfo="false"
          >
      </note>
    </div>
    <div class="notes-container md-layout-item md-size-100">
      <div v-if="!newNoteAsked">
        <span @click="showAndFocusNewNote">
            <md-icon class="icon-action icon-add">note_add</md-icon>
            <md-tooltip md-delay="300">Add new note</md-tooltip>
        </span>
      </div>
      <!--
      <small v-if="!newNoteAsked" @click="showAndFocusNewNote" class="ask-new-note">+ add new note</small>
      -->
      <note
          v-show="newNoteAsked"
          :note="newNote"
          @saveNote="onSaveNote"
          @deleteNote="onDeleteNewNote"
          @hasBeenFocused="focusNewNote"
          :isFocused="isNewNoteFocused()"
          :isNewNote="true"
          id="newNote"
          :ref="notes"
          :hideInfo="true"
          >
      </note>
    </div>
  </div>
</template>

<script>

import Note from '@/components/Avalia/Note/Note.vue';

export default {
  name: 'notes-container',
  components: {
    Note,
  },
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      noteIdFocused: 0,
      newNote: {
        text: '',
      },
      newNoteId: -1,
      newNoteAsked: false,
    };
  },
  computed: {
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
  },
  methods: {
    onSaveNote(notePayload) {
      if (this.isAnUpdatedNote(notePayload)) {
        this.$emit('onUpdateNote', notePayload);
      } else {
        this.$emit('onCreateNote', notePayload);
      }
    },
    isAnUpdatedNote(notePayload) {
      return notePayload.id !== null && notePayload.id !== undefined;
    },
    onDeleteNote(notePayload) {
      this.$emit('onDeleteNote', notePayload);
    },
    onDeleteNewNote() {
      // clear done by note
      this.showAndFocusNewNote();
    },
    haveBeenFocused(note) {
      this.noteIdFocused = note.id;
    },
    isNoteFocused(note) {
      return this.noteIdFocused === note.id;
    },
    isNewNoteFocused() {
      return this.noteIdFocused === this.newNoteId;
    },
    focusNextNote() {
      const currentNoteIndex = this.getCurrentNoteIndex();
      if (currentNoteIndex < this.notes.length - 1 && currentNoteIndex >= 0) {
        this.noteIdFocused = this.notes[currentNoteIndex + 1].id;
      } else if (currentNoteIndex !== this.newNoteId) {
        this.showAndFocusNewNote();
      }
    },
    focusPreviousNote() {
      const currentNoteIndex = this.getCurrentNoteIndex();
      if (currentNoteIndex > 0) {
        this.noteIdFocused = this.notes[currentNoteIndex - 1].id;
      } else if (currentNoteIndex === this.newNoteId) {
        this.noteIdFocused = this.notes[this.notes.length - 1].id;
      }
    },
    getCurrentNoteIndex() {
      return this.notes.map((note) => note.id).indexOf(this.noteIdFocused);
    },
    showAndFocusNewNote() {
      this.showNewNote();
      this.focusNewNote();
    },
    showNewNote() {
      this.newNoteAsked = true;
    },
    focusNewNote() {
      this.noteIdFocused = this.newNoteId;
    },
    showFocusAndScrollToNewNote() {
      this.showAndFocusNewNote();
      this.scrollToNewNote();
    },
    scrollToNewNote() {
      this.$scrollTo('#newNote');
    },
    hideNewNote() {
      this.newNoteAsked = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-new-note {
  cursor: pointer!important;
}
.notes-container{
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
}
</style>
