<template>
  <div class="task-view md-layout md-alignment-center">
    <div class="md-layout-item md-size-45 nopadding">
      <h6 class="task-name ">{{ task.name }}</h6>
    </div>
    <div class="md-layout-item md-size-55 nopadding complete-button-container">
      <!-- If the user has any of curator, avalia expert or external expert roles,
      he can Start Available Tasks, Work on Started Tasks or View Completed Tasks
      -->
      <div v-if="isNotReadOnly">
        <div v-if="loading">
          <md-progress-spinner
            md-mode="indeterminate"
            :md-diameter="20"
            :md-stroke="3"
          ></md-progress-spinner>
        </div>
        <div v-else-if="isTaskAvailable">
          <span @click="onStartTask">
            <md-icon class="icon-action icon-add">not_started</md-icon>
            <md-tooltip md-delay="300">Start Task</md-tooltip>
          </span>
        </div>
        <div v-else-if="isTaskStarted">
          <span @click="onWorkOnTask">
            <!-- <md-icon class="icon-action icon-add">engineering</md-icon> -->
            <md-icon class="icon-action icon-add"> <i class="fa fa-cogs"></i></md-icon>
            <md-tooltip md-delay="300">Work on Task</md-tooltip>
          </span>
        </div>
        <div v-else-if="isTaskCompleted">
          <span @click="onViewTask">
            <md-icon class="icon-action icon-add">search</md-icon>
            <md-tooltip md-delay="300">View Completed Task</md-tooltip>
          </span>
        </div>

        <div v-else>
          <span>
            <md-icon class="icon-locked">lock</md-icon>
            <md-tooltip md-delay="300">Task is Locked</md-tooltip>
          </span>
        </div>
      </div>
      <!-- Otherwise if the user has observer role, he can only View Started and Completed Tasks -->
      <div v-else-if="isTaskAvailable">
        <span>
          <md-icon class="icon-blocked">remove_circle</md-icon>
          <!-- alternative icon: block -->
          <md-tooltip md-delay="300">Observer Cannot Start Task</md-tooltip>
        </span>
      </div>
      <div v-else-if="this.isTaskStarted || this.isTaskCompleted">
        <span @click="onViewTask">
          <md-icon class="icon-action icon-add">search</md-icon>
          <!-- alternative icon: pageview -->
          <md-tooltip md-delay="300">View Task</md-tooltip>
        </span>
      </div>
      <!-- Task is locked -->
      <div v-else>
        <span>
          <md-icon class="icon-locked">lock</md-icon>
          <md-tooltip md-delay="300">Task is Locked</md-tooltip>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'single-task-view',
  props: {
    task: Object,
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    loading() {
      const loadingStartTask = this.$store.getters['project/GET_START_TASK_LOADING'];
      return loadingStartTask.taskId === this.task.id && loadingStartTask.loading;
    },
    isTaskAvailable() {
      return this.task.state === 'available';
    },
    isTaskStarted() {
      return this.task.state === 'in progress';
    },
    isTaskCompleted() {
      return this.task.state === 'completed';
    },
    isTaskLocked() {
      return this.task.state === 'locked';
    },
    isNotReadOnly() {
      return !this.isReadOnly;
    },
  },
  methods: {
    onStartTask() {
      this.$emit('startTask', this.task);
    },
    onWorkOnTask() {
      this.$emit('workOnTask', this.task);
    },
    onViewTask() {
      this.$emit('viewTask', this.task);
    },
  },
};
</script>

<style lang="scss" scoped>
.task-view {
  border-radius: 0.6em;
  background-color: rgba(188, 198, 204, 0.2);
  padding: 0.4rem 1rem 0.4rem 1rem;
  margin: 0rem 0.2rem 0.4rem 0rem;
  display: flex;
  justify-content: space-between;
}
.task-view:hover {
  background-color: rgba(188, 198, 204, 0.5);
}

.task-name {
  padding: 0rem 0rem 0rem 0rem;
  margin: 0rem 0rem 0rem;
}

.start-button {
  background-color: $avalia-gray !important;
}

.work-button {
  background-color: $avalia-cyan !important;
}

.view-button {
  background-color: $avalia-green !important;
}
.complete-button-container {
  text-align: right;
}
.state-label {
  cursor: default;
}
</style>
