<template>
  <modal @close="hide">
    <template slot="header">
      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="hide"
      >
        <md-icon>clear</md-icon>
      </md-button>
      <h4 class="modal-title">{{ title }}</h4>
    </template>

    <template slot="body">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100">
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ passed, failed }"
                ><md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <!-- Task Name -->
                  <label>Name</label>
                  <md-input
                    v-model="task.name"
                    type="text"
                    ref="taskNameField"
                    required
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <!-- Description -->
            <div
              class="md-layout-item md-size-100 mx-auto md-medium-size-100 description-field"
            >
            <ValidationProvider
                name="workInstructions"
                rules="required"
                v-slot="{ passed, failed }"
                >
              <md-field
              :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                <label>Work Instructions</label>
                <md-textarea
                  :maxlength="2000"
                  :md-autogrow="true"
                  v-model="task.workInstructions"
                  type="text"
                  required
                />

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
            </div>

            <div
              class="md-layout-item md-size-100 mx-auto md-medium-size-100 buttons-container"
            >
              <md-button
                class="md-danger modal-button md-sm cancel-button"
                @click="hide"
                >Cancel</md-button
              >
              <md-button class="md-success md-sm confirm-button" type="submit">
                Confirm
              </md-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>

import { SlideYDownTransition } from 'vue2-transitions';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { Modal } from '@/components/Template';

extend('required', required);

export default {
  name: 'create-task-modal',
  components: { Modal, SlideYDownTransition },
  props: {
    projectId: Number,
  },
  data() {
    return {
      title: 'Create Task',
      task: {
        name: '',
        workInstructions: '',
      },
    };
  },
  computed: {
  },
  watch: {
  },
  created() {

  },
  mounted() {
    // Set focus on task name field
    this.$refs.taskNameField.$el.focus();
  },
  methods: {
    hide() {
      this.$emit('close');
    },
    onSubmit() {
      this.$emit('createTask', { ...this.task, projectId: this.projectId });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-required {
  text-align: left;
  padding-left: 1rem;
  padding-top: 0.5rem;
  font-size: 14px;
}
.buttons-container {
  padding-top: 0.4rem;
}
.modal-button {
  margin-right: 0.4rem;
}
.link-field {
  padding-top: 0.4rem;
}
.description-field {
  padding-bottom: 0.4rem;
}
// CUSTOM BUTTONS TO MOVE TO avalia.scss
.confirm-button {
  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }
}
.cancel-button {
  color: #fe0600 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #fe0600 !important;
    opacity: 0.8 !important;
    border-color: #fe0600 !important;
  }
}
</style>
