var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{on:{"close":_vm.hide}},[_c('template',{slot:"header"},[_c('md-button',{staticClass:"md-simple md-just-icon md-round modal-default-button",on:{"click":_vm.hide}},[_c('md-icon',[_vm._v("clear")])],1),_c('h4',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.title))])],1),_c('template',{slot:"body"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 mx-auto md-medium-size-100"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Name")]),_c('md-input',{ref:"taskNameField",attrs:{"type":"text","required":""},model:{value:(_vm.task.name),callback:function ($$v) {_vm.$set(_vm.task, "name", $$v)},expression:"task.name"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-size-100 mx-auto md-medium-size-100 description-field"},[_c('ValidationProvider',{attrs:{"name":"workInstructions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Work Instructions")]),_c('md-textarea',{attrs:{"maxlength":2000,"md-autogrow":true,"type":"text","required":""},model:{value:(_vm.task.workInstructions),callback:function ($$v) {_vm.$set(_vm.task, "workInstructions", $$v)},expression:"task.workInstructions"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-size-100 mx-auto md-medium-size-100 buttons-container"},[_c('md-button',{staticClass:"md-danger modal-button md-sm cancel-button",on:{"click":_vm.hide}},[_vm._v("Cancel")]),_c('md-button',{staticClass:"md-success md-sm confirm-button",attrs:{"type":"submit"}},[_vm._v(" Confirm ")])],1)])])]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }